<template>
    <div>
        <h2>Удалить email из стоп-листа</h2>
        <div class="input-wrapper">

            <b-field label="Отправитель">
                <b-input type="email" v-model="remove_data.email_from"></b-input>
            </b-field>

            <b-field label="Получатель" message="Можно указать несколько адресов через запятую">    
                <b-input type="text" v-model="remove_data.emails_to_remove"></b-input>
            </b-field>

            <button 
                type="button" 
                class="button is-primary"
                :disabled="isLoading"
                @click="deleteFromStoplist"
                >
                <span>Удалить</span>
            </button>

        </div>
        <b-loading :is-full-page="true" :active='isLoading'></b-loading>
        <div class="response-block" v-if="response_data.length">
            
            <ul>
                <li v-for='(value, key) in response_data' :key="key">{{value.email}} : {{ value.status }} ({{ value.message }})</li>
            </ul>
        </div>
        
    </div>
</template>

<script>
    import axiois from 'axios'
    export default {

        name: 'DeleteFromStoplist',
        data() {
            return {
                isLoading: false,
                remove_data: {
                    email_from: '',
                    emails_to_remove:'',
                },
                response_data: {},
                authToken: '',
                test_sender_email: /^([A-Za-z0-9_\-\.\+])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,})$/,
                test_emails_to_delete: /^((\w+[-\.+]*\w*)+\@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z0-9]{2,}\s*?,?\s*?)+$/
            }
        },
        mounted() {
            this.authToken = localStorage.getItem('lh-admin-panel-auth-token');
        },
        methods: {
            async deleteFromStoplist() {
                if( this.test_emails_to_delete.test(this.remove_data.emails_to_remove) && 
                    this.test_sender_email.test(this.remove_data.email_from)) 
                {
                    this.isLoading = true

                   let data = Object.assign({}, this.remove_data)

                   data.emails_to_remove = data.emails_to_remove.split(',')

                   //убираем пробелы

                   data.emails_to_remove = data.emails_to_remove.map((el) =>  el.trim())

                    await axiois.post(`${this.$store.getters.admin_api_url}/tools/remove_email_from_stoplist`, data, {
                        headers: {
                            'Accept': 'application/json',
                            'Accept-Language' : 'en',
                            'Content-Type':'application/json',
                            'Auth-Token': this.authToken
                        },
                        
                    })
                    .then((resp) => {
                        this.response_data = resp.data
                        this.remove_data.email_from = ''
                        this.remove_data.emails_to_remove = ''
                        this.isLoading = false
                        
                    })

                    .catch(e => console.log('Error in remove from stoplist request',e))
                    return
                }
                return this.$message('Заполните адреса для удаления корректно', 'danger')
            }
        }
    }
</script>

<style lang="scss" scoped>
    h2 {
        margin-bottom: 52px;
    }
    .field {
        margin-bottom: 24px !important;
    }
    .input-wrapper {
        width: 30%;
        margin-bottom: 64px;
    }
    
    .response-block {
        position: relative;
    margin-top: 24px;
    min-height: 50%;
        li {
            margin-bottom: 16px;
        }
    }
</style>